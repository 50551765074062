import { useTheme } from "@emotion/react";
import {
  Dispatch,
  forwardRef,
  RefObject,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FTSTheme } from "../../../../App";
import TooltipDimension from "../../../../components/TooltipDimension/TooltipDimension";
import { ABB } from "../../../../constants/Theme";
import { useStoreState } from "../../../../hooks";
import { IElementSize } from "../../../../interfaces/DTO/IElementSize";
import { EInsertDetailItemType, IInsertDetail } from "../../../../interfaces/IInsertDetail";
import { ITileDimension } from "../../../../interfaces/ITileDimension";
import DimensionLine from "./DimensionLine";
import { IRockerTextLabelProps, isHorizontal, ITextLabelSizes, LimitChar } from "./limitChar";
import { getElementPosition } from "./utils";
import CoverItemsFunctions from "./utils-cover-items";

interface ICanvasProps {
  showCanvas: boolean;
  textAreaValue: string;
  textAreaPreviewValue: string;
  fontSizeCanvas: number;
  lineHeight: number;
  indexFontSize: number;
  textLabelSizes: ITextLabelSizes | IRockerTextLabelProps;
  insertDetail: IInsertDetail;
  isRocker: boolean;
  indexDetail: number;
  cover: number;
  designCode: string;
  tileName: string;
  tileDimension: ITileDimension;
  setInsertDetail: (detail: IInsertDetail) => void;
  setTextAreaValue: Dispatch<SetStateAction<string>>;
  setTextAreaPreviewValue: Dispatch<SetStateAction<string>>;
  setShowCanvas: Dispatch<SetStateAction<boolean>>;
  setDisplayHighlightedWarning: Dispatch<SetStateAction<{ display: boolean; start: number; end: number }>>;
  setCurrentCanvasValue: Dispatch<SetStateAction<string>>;

  coverRef?: RefObject<HTMLDivElement>;
}
export interface ICanvasForwardProps {
  refresh: Function;
  reDraw: Function;
  handleOnKeyUp: (value: string, start: number, end: number) => void;
  handleGetCanvasDimension: Function;
}

const MULTIPLIER = 10;

var preventKeypress = false;

const Canvas: React.ForwardRefRenderFunction<ICanvasForwardProps, ICanvasProps> = (props, ref) => {
  const ftsTheme = useTheme() as FTSTheme;

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const validateCanvasRef = useRef<HTMLCanvasElement>(null);

  let canvasContext: any = null;
  let validateCanvasContext: any = null;

  const arrLimitTextLabel = [
    props.textLabelSizes.small,
    props.textLabelSizes.standard,
    props.textLabelSizes.medium,
    props.textLabelSizes.large,
    props.textLabelSizes.xlarge,
  ];

  const canvasColor = props.tileDimension.isFunction
    ? props.insertDetail?.insertColor?.secondaryPrintColor?.hexColor!
    : props.insertDetail?.insertColor?.primaryPrintColor?.hexColor!;

  const textFieldProps = LimitChar.get(props.designCode)!;
  const [showDimensions, setShowDimensions] = useState(false);

  const { enableDimensionComponent } = useStoreState((state) => state.app);
  const { designSizes } = useStoreState((state) => state.designs.data);

  useEffect(() => {
    canvasContext = canvasRef?.current?.getContext("2d");
    validateCanvasContext = validateCanvasRef?.current?.getContext("2d");
  }, []);

  useEffect(() => {
    if (canvasContext != null) {
      validateCanvasContext.font = `400 ${props.fontSizeCanvas}pt ${ftsTheme.name == ABB ? "ABBvoice" : "BJEAverta"}`;
      validateCanvasContext.textAlign = `center`;

      canvasContext.font = `400 ${props.fontSizeCanvas * MULTIPLIER}pt ${ftsTheme.name == ABB ? "ABBvoice" : "BJEAverta"}`;
      canvasContext.textAlign = `center`;
      canvasContext.fillStyle = canvasColor;
    }
  }, [canvasContext]);

  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [boundingClientRect, setBoundingClientRect] = useState({});

  const getNewWidth = (insertDetail: IInsertDetail, newRotate?: number) => {
    if (newRotate == null && (insertDetail.rotate == null || insertDetail.rotate == 0))
      return props.textLabelSizes.canvas?.width;

    const rotate = newRotate ?? insertDetail.rotate!;

    if (props.isRocker) {
      return isHorizontal(rotate)
        ? textFieldProps.horizontal[props.tileName].rocker.canvas.width
        : textFieldProps.vertical[props.tileName].rocker.canvas.width;
    }

    return isHorizontal(rotate)
      ? textFieldProps.horizontal[props.tileName].canvas.width
      : textFieldProps.vertical[props.tileName].canvas.width;
  };

  const getMaxRows = (insertDetail: IInsertDetail, indexFontSize: number, newRotate?: number) => {
    const rotate = newRotate ?? insertDetail.rotate!;
    const orientation = isHorizontal(rotate!) ? "horizontal" : "vertical";
    const textProps = textFieldProps[orientation][props.tileName];
    let arrLimitTextLabel = [textProps.small, textProps.standard, textProps.medium, textProps.large, textProps.xlarge];
    let maxRows = arrLimitTextLabel[indexFontSize].maxRows;

    if (props.isRocker) {
      arrLimitTextLabel = [
        textProps.rocker.small,
        textProps.rocker.standard,
        textProps.rocker.medium,
        textProps.rocker.large,
        textProps.rocker.xlarge,
      ];
      maxRows = arrLimitTextLabel[indexFontSize].maxRows;
    }

    return maxRows as number;
  };

  const handleOnKeyUp = (value: string, start: number, end: number) => {
    if (start != end) return;

    validateCanvasContext = validateCanvasRef?.current?.getContext("2d");
    validateCanvasContext.font = `400 ${props.fontSizeCanvas}pt ${ftsTheme.name == ABB ? "ABBvoice" : "BJEAverta"}`;
    validateCanvasContext.textAlign = `center`;

    canvasContext = canvasRef?.current?.getContext("2d");
    canvasContext.font = `400 ${props.fontSizeCanvas * MULTIPLIER}pt ${ftsTheme.name == ABB ? "ABBvoice" : "BJEAverta"}`;
    canvasContext.textAlign = `center`;
    canvasContext.fillStyle = canvasColor;
    preventKeypress = false;

    var size = arrLimitTextLabel[props.indexFontSize];
    const maxRows = size.maxRows;
    const canvasWidth = getNewWidth(props.insertDetail, props.insertDetail.rotate);
    var x = (canvasWidth * MULTIPLIER) / 2;
    var y = 0;
    const lineBreak = `\n`;

    //put each character typed in an array
    const characters = value.split("");
    let text = "";
    let currentLine = 0;
    let highlightText = "";
    let lines: string[] = [text];

    const lineBreakPaste = `\n`;
    for (let index = 0; index < characters.length; index++) {
      //if text achieve maxRows and exists characters yet, add character to highlight
      if (currentLine == maxRows && characters[index] != lineBreakPaste) {
        highlightText += characters[index];
        continue;
      }

      text = lines[currentLine] || "";
      //if not ENTER, add character to string
      if (characters[index] != lineBreakPaste) {
        text += characters[index];
      } else {
        //if ENTER, break line
        if (currentLine == 1) {
          let textValidateMetrics1 = validateCanvasContext?.measureText(text);
          if (textValidateMetrics1?.width! < canvasWidth!) {
            lines[currentLine] = text;
            continue;
          }
        }

        lines[currentLine] = text;
        currentLine++;
        continue;
      }

      const textValidateMetrics = validateCanvasContext?.measureText(text) as TextMetrics;
      lines[currentLine] = text;

      if (textValidateMetrics?.width! > canvasWidth!) {
        lines[currentLine] = text.substring(0, text.length - 1);
        currentLine++;

        if (currentLine < maxRows) lines[currentLine] = characters[index];
        else {
          highlightText += characters[index];
        }
      } else {
        lines[currentLine] = text;
      }
    }

    let valueForCanvas = "";
    let valueForTextArea = "";

    if (lines[0]) {
      valueForCanvas += lines[0];
      valueForTextArea += lines[0];
    }

    if (lines[1] && maxRows > 1) {
      valueForCanvas += lineBreak + lines[1];
      valueForTextArea += lineBreak + lines[1];
    }

    //if user pressed ENTER and dont achieve maxRows yet
    if (value.endsWith("\n") && lines.length < maxRows) {
      valueForTextArea += lineBreak;
    }

    //if has highlight add it to textarea value
    if (highlightText.length > 0) {
      valueForTextArea += highlightText;
    }

    var linesToWrite: string[] = valueForCanvas.split(lineBreak) as string[];

    let ratio = window.devicePixelRatio || 1; //1 for legacy browsers
    const calculatedNewCanvasHeight = props.fontSizeCanvas * lines.length + 10;

    //set canvas height based on amount lines typed
    validateCanvasRef.current!.height = calculatedNewCanvasHeight;
    canvasRef.current!.height = calculatedNewCanvasHeight * MULTIPLIER;
    validateCanvasContext = validateCanvasRef?.current?.getContext("2d");
    validateCanvasContext.font = `400 ${props.fontSizeCanvas}pt ${ftsTheme.name == ABB ? "ABBvoice" : "BJEAverta"}`;
    validateCanvasContext.textAlign = `center`;

    canvasContext = canvasRef?.current?.getContext("2d");
    canvasContext.font = `400 ${props.fontSizeCanvas * MULTIPLIER}pt ${ftsTheme.name == ABB ? "ABBvoice" : "BJEAverta"}`;
    canvasContext.textAlign = `center`;
    canvasContext.fillStyle = canvasColor;
    //set canvas height based on amout lines typed

    //fill canvas with typed text
    for (var i = 0; i < linesToWrite.length; i++) {
      let newY = y + props.lineHeight + i * props.lineHeight;
      newY = i > 0 ? newY + 8 : newY + 2;
      canvasContext?.fillText(linesToWrite[i], x, newY * MULTIPLIER);
    }

    //compare canvas cutted text with textarea text to set highlight
    const textAreaLength = value.replace(lineBreak, "").length;
    const textCanvasLength = valueForCanvas.replace(lineBreak, "").length;
    props.setDisplayHighlightedWarning({
      display: textAreaLength > textCanvasLength,
      start: valueForCanvas.length,
      end: props.textAreaValue.length - 1,
    });

    props.setTextAreaValue(valueForTextArea);
    props.setTextAreaPreviewValue(valueForCanvas);
    // props.setInsertDetail({ ...props.insertDetail, [propName]: valueForCanvas });

    const color = props.tileDimension.isFunction
      ? props.insertDetail?.insertColor?.secondaryPrintColor?.ralColorCode!
      : props.insertDetail?.insertColor?.primaryPrintColor?.ralColorCode!;

    CoverItemsFunctions.addOrUpdateText({
      indexDetail: props.indexDetail,
      insertDetail: props.insertDetail!,
      setInsertDetail: props.setInsertDetail,
      coverIndex: props.cover,
      color: color,
      textValue: valueForCanvas,
      size: props.fontSizeCanvas,
      name: `${ftsTheme.name == ABB ? "ABBvoice" : "BJEAverta"}`,
      style: "normal",
      weight: "normal",
      lineSpacing: 1,
      designSizes: designSizes,
    });
  };

  const reDraw = (
    fontSizeCanvas: number,
    lineHeight: number,
    textAreaValue: string,
    indexFontSize: number,
    canvasHeight: number,
    save: boolean = true,
    insertDetail: IInsertDetail,
    rotate?: number // it's coming from handleRotate only
  ) => {
    props.setTextAreaPreviewValue(textAreaValue);
    const canvasColor = props.tileDimension.isFunction
      ? insertDetail?.insertColor?.secondaryPrintColor?.hexColor!
      : insertDetail?.insertColor?.primaryPrintColor?.hexColor!;

    const canvasWidth = getNewWidth(insertDetail, rotate);
    const maxNumberOfRows = getMaxRows(insertDetail, indexFontSize, rotate);
    validateCanvasRef.current!.width = canvasWidth!;
    validateCanvasRef.current!.height = canvasHeight;
    validateCanvasContext = validateCanvasRef?.current?.getContext("2d");
    validateCanvasContext.font = `400 ${fontSizeCanvas}pt ${ftsTheme.name == ABB ? "ABBvoice" : "BJEAverta"}`;
    validateCanvasContext.textAlign = `center`;

    canvasRef.current!.width = canvasWidth! * MULTIPLIER;
    canvasRef.current!.height = canvasHeight * MULTIPLIER;
    canvasContext = canvasRef?.current?.getContext("2d");
    canvasContext.font = `400 ${fontSizeCanvas * MULTIPLIER}pt ${ftsTheme.name == ABB ? "ABBvoice" : "BJEAverta"}`;
    canvasContext.textAlign = `center`;
    canvasContext.fillStyle = canvasColor;
    canvasContext.shadowColor = "red";

    canvasContext?.clearRect(0, 0, canvasWidth * MULTIPLIER, canvasHeight * MULTIPLIER);
    validateCanvasContext?.clearRect(0, 0, canvasWidth, canvasHeight);

    const characters = textAreaValue.split("");
    let text = "";
    let currentLine = 0;
    let lines: string[] = [text];

    const lineBreak = `\n`;
    for (let index = 0; index < characters.length; index++) {
      if (currentLine == maxNumberOfRows) {
        break;
      }

      text = lines[currentLine] || "";

      if (characters[index] != lineBreak) {
        text += characters[index];
      } else {
        if (currentLine == 1) {
          let textValidateMetrics1 = validateCanvasContext?.measureText(text);
          if (textValidateMetrics1?.width! < canvasWidth!) {
            lines[currentLine] = text;
            continue;
          }
        }

        lines[currentLine] = text;
        currentLine++;
        continue;
      }
      const textValidateMetrics = validateCanvasContext?.measureText(text) as TextMetrics;

      lines[currentLine] = text;

      if (textValidateMetrics?.width! > canvasWidth!) {
        lines[currentLine] = text.substring(0, text.length - 1);
        currentLine++;

        if (currentLine < maxNumberOfRows) lines[currentLine] = characters[index];
      } else {
        lines[currentLine] = text;
      }
    }

    let newTextAreaValue = "";

    if (lines[0]) newTextAreaValue += lines[0];

    if (lines[1] && maxNumberOfRows > 1) {
      newTextAreaValue += lineBreak + lines[1];
    }

    if (
      newTextAreaValue.replace(lineBreak, "").trim().length ==
      props.textAreaPreviewValue.replace(lineBreak, "").trim().length
    ) {
      props.setTextAreaValue(newTextAreaValue);
    }

    const textareaLength = props.textAreaValue.length;
    const textCanvasLength = newTextAreaValue.length;

    const display = textareaLength > textCanvasLength;
    props.setDisplayHighlightedWarning({
      display: display,
      start: newTextAreaValue.length,
      end: props.textAreaPreviewValue.length,
    });

    var linesToWrite = newTextAreaValue.split(lineBreak);

    let dpr = window.devicePixelRatio || 1; //1 for legacy browsers
    const calculatedNewCanvasHeight = fontSizeCanvas * lines.length + 10;

    //set canvas height based on amount lines typed
    validateCanvasRef.current!.height = calculatedNewCanvasHeight;
    canvasRef.current!.height = calculatedNewCanvasHeight * MULTIPLIER;
    validateCanvasContext = validateCanvasRef?.current?.getContext("2d");
    validateCanvasContext.font = `400 ${fontSizeCanvas}pt ${ftsTheme.name == ABB ? "ABBvoice" : "BJEAverta"}`;
    validateCanvasContext.textAlign = `center`;

    canvasContext = canvasRef?.current?.getContext("2d");
    canvasContext.font = `400 ${fontSizeCanvas * MULTIPLIER}pt ${ftsTheme.name == ABB ? "ABBvoice" : "BJEAverta"}`;
    canvasContext.textAlign = `center`;
    canvasContext.fillStyle = canvasColor;

    setCanvasHeight(calculatedNewCanvasHeight);
    setCanvasWidth(canvasWidth);

    //set canvas height based on amout lines typed

    const x = (canvasWidth! * MULTIPLIER) / 2;
    const y = 0;

    for (var i = 0; i < linesToWrite.length; i++) {
      let newY = y + lineHeight + i * lineHeight;
      newY = i > 0 ? newY + 8 : newY + 2;
      canvasContext?.fillText(linesToWrite[i], x, newY * MULTIPLIER);
    }

    if (save) {
      CoverItemsFunctions.addOrUpdateText({
        indexDetail: props.indexDetail,
        insertDetail: props.insertDetail!,
        setInsertDetail: props.setInsertDetail,
        coverIndex: props.cover,
        color: canvasColor,
        textValue: newTextAreaValue,
        size: fontSizeCanvas,
        name: `${ftsTheme.name == ABB ? "ABBvoice" : "BJEAverta"}`,
        style: "normal",
        weight: "normal",
        lineSpacing: 1,
        designSizes: designSizes,
      });
    }

    return insertDetail as IInsertDetail;
  };

  const refresh = (rotate: number, insertDetail: IInsertDetail, reset?: boolean) => {
    const newHeight = props.fontSizeCanvas * props.textAreaValue.split("\n").length;

    if (reset) {
      props.setTextAreaValue("");
      props.setShowCanvas(false);
      reDraw(props.fontSizeCanvas, props.lineHeight, "", props.indexFontSize, newHeight, false, insertDetail, rotate);
      return;
    } else {
      const newDetailRedraw = reDraw(
        props.fontSizeCanvas,
        props.lineHeight,
        props.textAreaValue,
        props.indexFontSize,
        newHeight,
        false,
        insertDetail,
        rotate
      );
      return { ...insertDetail, ...newDetailRedraw } as IInsertDetail;
    }
  };

  useEffect(() => {
    if (canvasRef.current) {
      setBoundingClientRect(canvasRef.current?.getBoundingClientRect()!);
    }
  }, [canvasRef.current?.getBoundingClientRect().height, canvasRef.current?.getBoundingClientRect().x]);

  const handleGetCanvasDimension = () => {
    return {
      width: getCanvasSize().width,
      height: getCanvasSize().height,
      boundingClientRect: canvasRef.current?.getBoundingClientRect(),
      ref: canvasRef,
    };
  };

  useImperativeHandle(ref, () => {
    return {
      refresh: refresh,
      reDraw: reDraw,
      handleOnKeyUp: handleOnKeyUp,
      handleGetCanvasDimension: handleGetCanvasDimension,
    };
  });

  const getCanvasSize = (): IElementSize => {
    const elementSize: IElementSize = {
      width: canvasWidth,
      height: canvasHeight,
    };
    return elementSize;
  };

  const withoutIcon = !props.insertDetail.items.some(
    (item) => item.type === EInsertDetailItemType.Icon && item.indexDetail === props.indexDetail
  );

  const textStyle = `tile-div-canvas ${
    withoutIcon
      ? "without-icon"
      : props.tileDimension.isFunction
        ? "function"
        : props.isRocker
          ? "button-rocker"
          : "default"
  }`;

  return (
    <>
      <div
        className={textStyle}
        style={{
          height: props.showCanvas ? "auto" : "0px",
          display: props.textAreaValue.length ? "flex" : "none",
        }}
      >
        <TooltipDimension
          coverRef={props.coverRef!}
          elementRef={canvasRef}
          getElementSize={getCanvasSize}
          enableDimensionLine={true}
          enableDimensionBorder={false}
          enableStartEndPositions={false}
          styleProps={{
            width: "100%",
          }}
          insertDetail={props.insertDetail}
          indexDetail={props.indexDetail}
          designSizes={designSizes}
        >
          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <DimensionLine
              show={enableDimensionComponent && showDimensions}
              dimensions={getElementPosition(props.coverRef!, canvasRef, getCanvasSize)!}
            />
            <canvas
              ref={canvasRef}
              id="myCanvas"
              style={{
                width: "100%",
                cursor: "pointer",
                padding: "0px 0",
                flex: "1 1 auto",
                border: `${enableDimensionComponent && showDimensions ? "1px solid red" : ""}`,
                // backgroundColor: '#ff000044'
              }}
            >
              Your browser does not support the canvas element.
            </canvas>
          </div>
        </TooltipDimension>
        <canvas
          ref={validateCanvasRef}
          id="myCanvaValidate"
          style={{ display: "none" }}
        >
          Your browser does not support the canvas element.
        </canvas>
      </div>
    </>
  );
};

export default forwardRef(Canvas);
